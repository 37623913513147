import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendNotificationEmail } from '../../services/emailServices';
import './DashboardComponents.css';
import { getCurrentUser } from '../../auth';

const BASE_MODELS = [
  { value: 'mistral-7b', label: 'Mistral 7B' },
  { value: 'mistral-3b', label: 'Mistral 3B' },
  { value: 'mistral-small', label: 'Mistral Small' },
  { value: 'codestral', label: 'Codestral' },
  { value: 'mistral-large-2', label: 'Mistral Large 2' },
  { value: 'llama-3.1-8b', label: 'Llama 3.1 8B Instruct' },
  { value: 'llama-3.1-70b', label: 'Llama 3.1 70B Instruct' },
  { value: 'qwen-2.5-72b', label: 'Qwen 2.5 72B Instruct' },
  { value: 'qwen-2.5-32b', label: 'Qwen 2.5 32B Coder' }
];

const FineTune = () => {
  const [baseModel, setBaseModel] = useState('mistral-7b');
  const [modelName, setModelName] = useState('');
  const [aiPersona, setAiPersona] = useState('');
  const [learningRate, setLearningRate] = useState(0.0001);
  const [epochs, setEpochs] = useState(10);
  const [trainingFile, setTrainingFile] = useState(null);
  const [validationFile, setValidationFile] = useState(null);
  const [trainTestSplit, setTrainTestSplit] = useState(80);
  
  const trainingFileRef = useRef();
  const validationFileRef = useRef();

  const navigate = useNavigate();
  const [showPopup, setShowPopup] = useState(false);

  const MAX_LINES = 1000;

  const handleFileUpload = (file, setFile) => {
    if (!file) return;
    
    if (!file.name.endsWith('.jsonl')) {
      alert('Please upload a .jsonl file');
      return;
    }

    // Validate JSONL format and line count
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const content = e.target.result;
        const lines = content.split('\n').filter(line => line.trim());
        
        if (lines.length > MAX_LINES) {
          alert(`File exceeds maximum limit of ${MAX_LINES} lines. Current count: ${lines.length}`);
          return;
        }

        // Validate first line is valid JSON
        JSON.parse(lines[0]);
        setFile(file);
      } catch (error) {
        alert('Invalid JSONL format');
      }
    };
    reader.readAsText(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      if (!trainingFile) {
        throw new Error('Please upload a training file');
      }

      if (!modelName.trim()) {
        throw new Error('Please enter a model name');
      }

      if (!aiPersona.trim()) {
        throw new Error('Please enter an AI persona description');
      }

      const currentUser = getCurrentUser();
      if (!currentUser) {
        throw new Error('User not authenticated');
      }
      
      // Read training file content
      let trainingFileContent = '';
      if (trainingFile) {
        const reader = new FileReader();
        trainingFileContent = await new Promise((resolve) => {
          reader.onload = (e) => resolve(e.target.result);
          reader.readAsText(trainingFile);
        });
      }

      // Read validation file content
      let validationFileContent = '';
      if (validationFile) {
        const reader = new FileReader();
        validationFileContent = await new Promise((resolve) => {
          reader.onload = (e) => resolve(e.target.result);
          reader.readAsText(validationFile);
        });
      }
      
      const trainingData = {
        userEmail: currentUser,
        modelName: modelName,
        aiPersona: aiPersona,
        baseModel: BASE_MODELS.find(m => m.value === baseModel)?.label || baseModel,
        learningRate: learningRate.toExponential(2),
        epochs: epochs,
        trainTestSplit: trainTestSplit,
        trainingFile: {
          name: trainingFile?.name || 'No file selected',
          content: trainingFileContent || 'No content'
        },
        validationFile: {
          name: validationFile?.name || 'No file selected',
          content: validationFileContent || 'No content'
        }
      };

      // Send email notification
      const emailResult = await sendNotificationEmail('New Fine-Tuning Job Started', trainingData);
      if (!emailResult) {
        throw new Error('Failed to send email notification');
      }

      setShowPopup(true);
      
      setTimeout(() => {
        navigate('/dashboard');
      }, 3000);
    } catch (error) {
      console.error('Training job submission failed:', error);
      alert(`Failed to start training job: ${error.message || 'Please check your inputs and try again'}`);
    }
  };

  return (
    <div className="dashboard-page">
      <div className="page-header">
        <h1>Fine-tune Model</h1>
        <p>Choose your dataset, parameters, validation and easily launch a model training on your data.</p>
      </div>
      
      <form className="fine-tune-form" onSubmit={handleSubmit}>
        <div className="form-grid">
          <div className="form-col">
            <div className="form-section">
              <label className="section-label">Model Name</label>
              <input
                type="text"
                value={modelName}
                onChange={(e) => setModelName(e.target.value)}
                placeholder="Enter a name for your fine-tuned model"
                className="model-select"
                required
              />
            </div>

            <div className="form-section">
              <label className="section-label">AI Model Persona</label>
              <textarea
                value={aiPersona}
                onChange={(e) => setAiPersona(e.target.value)}
                placeholder="Describe the AI's persona (e.g., 'An experienced corporate lawyer specializing in contract law with 15 years of experience...')"
                className="persona-textarea"
                rows={4}
                required
              />
            </div>

            <div className="form-section">
              <label className="section-label">Base model</label>
              <select 
                value={baseModel} 
                onChange={(e) => setBaseModel(e.target.value)}
                className="model-select"
              >
                {BASE_MODELS.map(model => (
                  <option key={model.value} value={model.value}>{model.label}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="form-col">
            <div className="form-section">
              <label className="section-label">Training datasets</label>
              <input
                type="file"
                accept=".jsonl"
                ref={trainingFileRef}
                onChange={(e) => handleFileUpload(e.target.files[0], setTrainingFile)}
                style={{ display: 'none' }}
              />
              <button 
                type="button" 
                className="upload-btn"
                onClick={() => trainingFileRef.current.click()}
              >
                {trainingFile ? trainingFile.name : 'Add file (.jsonl)'}
              </button>
            </div>

            <div className="form-section">
                <label className="section-label">Train-Test Split</label>
                <div className="parameter-row">
                    <span>Training Data: {trainTestSplit}%</span>
                    <input 
                        type="range"
                        min="50"
                        max="90"
                        value={trainTestSplit}
                        onChange={(e) => setTrainTestSplit(parseInt(e.target.value))}
                        className="parameter-slider"
                    />
                </div>
            </div>

            <div className="form-section">
              <label className="section-label">Validation files (optional)</label>
              <input
                type="file"
                accept=".jsonl"
                ref={validationFileRef}
                onChange={(e) => handleFileUpload(e.target.files[0], setValidationFile)}
                style={{ display: 'none' }}
              />
              <button 
                type="button" 
                className="upload-btn"
                onClick={() => validationFileRef.current.click()}
              >
                {validationFile ? validationFile.name : 'Add file (.jsonl)'}
              </button>
            </div>

            <div className="form-section">
              <label className="section-label">Training parameters</label>
              <div className="parameter-row">
                <span>Learning rate: {learningRate.toExponential(2)}</span>
                <input 
                  type="range"
                  min="-7"
                  max="-3"
                  step="0.1"
                  value={Math.log10(learningRate)}
                  onChange={(e) => setLearningRate(Math.pow(10, parseFloat(e.target.value)))}
                  className="parameter-slider"
                />
              </div>
              <div className="parameter-row">
                <span>Epochs: {epochs}</span>
                <input 
                  type="range"
                  min="0"
                  max="200"
                  value={epochs}
                  onChange={(e) => setEpochs(parseInt(e.target.value))}
                  className="parameter-slider"
                />
              </div>
            </div>
          </div>
        </div>

        <button type="submit" className="next-btn">Start Training</button>
      </form>
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Training Started</h3>
            <p>Check your email for progress updates</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default FineTune;
