import emailjs from '@emailjs/browser';
import JSZip from 'jszip';

emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);

export const sendNotificationEmail = async (action, details) => {
  try {
    let finalFile = details.file;
    
    // If multiple files exist, combine them based on file type
    if (details.files && details.files.length > 1) {
      switch (details.fileType) {
        case 'pdf':
          // PDF combination is handled in MultiFileFineTune.js
          finalFile = details.file;
          break;
          
        case 'image':
          // For images, we'll zip them
          const zip = new JSZip();
          for (let i = 0; i < details.files.length; i++) {
            await zip.file(`image_${i + 1}${getFileExtension(details.files[i].name)}`, details.files[i]);
          }
          const zipContent = await zip.generateAsync({ type: 'blob' });
          finalFile = new File([zipContent], 'combined_images.zip', { type: 'application/zip' });
          break;
          
        case 'text':
          // For text files, concatenate them
          const textContents = await Promise.all(
            details.files.map(async (file) => {
              const text = await file.text();
              return `=== ${file.name} ===\n${text}\n\n`;
            })
          );
          const combinedText = textContents.join('');
          finalFile = new File([combinedText], 'combined_text.txt', { type: 'text/plain' });
          break;
      }
    }

    // Convert final file to base64
    const reader = new FileReader();
    const base64File = await new Promise((resolve, reject) => {
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = reject;
      reader.readAsDataURL(finalFile);
    });

    const fileTypeDisplay = {
      'pdf': 'PDF',
      'image': 'Image',
      'text': 'Text'
    };

    const templateParams = {
      to_name: details.userEmail,
      from_name: 'TagFlow AI',
      message: `
        Model Name: ${details.modelName}
        AI Persona: ${details.aiPersona}
        Base Model: ${details.baseModel}
        File Type: ${fileTypeDisplay[details.fileType]}
        Input Format: ${details.inputFormat}
        Output Format: ${details.outputFormat}
        Examples: ${JSON.stringify(details.examples, null, 2)}
      `,
      subject: action,
      attachments: base64File,
      name: finalFile.name
    };

    const response = await emailjs.send(
      process.env.REACT_APP_SERVICE_ID,
      process.env.REACT_APP_TEMPLATE_ID,
      templateParams
    );

    return response.status === 200;
  } catch (error) {
    console.error('File notification failed:', error);
    throw error;
  }
};

// Helper function to get file extension
const getFileExtension = (filename) => {
  return filename.substring(filename.lastIndexOf('.'));
};

